@font-face {
  font-family: 'Alex Brush';
  src: url("../fonts/AlexBrush-Regular.ttf") format("truetype"); }
@font-face {
  font-family: 'Coolvetica Regular';
  src: url("../fonts/coolvetica-rg.otf") format("truetype"); }
@font-face {
  font-family: 'Coolvetica Black';
  src: url("../fonts/CoolveticaBk-Regular.ttf") format("truetype");
  font-style: normal; }
@font-face {
  font-family: 'Coolvetica Regular Italic';
  src: url("../fonts/coolvetica-rg-it.otf") format("truetype"); }
@font-face {
  font-family: 'Play Fair Display Regular';
  src: url("../fonts/PlayfairDisplay-Regular.ttf") format("truetype"); }
@font-face {
  font-family: 'Play Fair Display Italic';
  src: url("../fonts/PlayfairDisplay-Italic.ttf") format("truetype"); }
@font-face {
  font-family: 'Play Fair Display Bold';
  src: url("../fonts/PlayfairDisplay-Bold.ttf") format("truetype"); }
@font-face {
  font-family: 'Great Vibes';
  src: url("../fonts/GreatVibes-Regular.ttf") format("truetype"); }
@keyframes fade {
  0% { opacity: 0; }
  10% { opacity: 0; }
  100% { opacity: 1; } }
@keyframes fade2 {
  0% { opacity: 0; }
  25% { opacity: 0; }
  100% { opacity: 1; } }
@keyframes fadeAway {
  0% { opacity: 1; }
  10% { opacity: 0; }
  100% { opacity: 0; } }
@keyframes fadeAway2 {
  0% { opacity: 1; }
  20% { opacity: 0; }
  100% { opacity: 0; } }
@keyframes moveAway {
  0% { transform: translateY(0px); }
  10% { transform: translateY(0px); }
  100% { transform: translateY(50vh); } }
@keyframes moveAway2 {
  0% {transform: translateY(0px); }
  10% {transform: translateY(0px); }
  100% {transform: translateY(100vh); } }
@keyframes moveAway3 {
  0% {transform: translateY(0px); }
  10% { transform: translateY(0px); opacity: 1; }
  50% { transform: translateY(-10vh); opacity: 1; }
  100% { transform: translateY(-10vh); opacity: 0; } }
@keyframes move3 {
  0% {transform: translateY(-10vh); }
  10% {transform: translateY(-10vh); }
  100% {transform: translateY(0px); } }
  .fade-2s { animation: fade 2s ease; animation-fill-mode: forwards; }
.fade-away-bg { animation: fadeBg 2s ease; animation-fill-mode: forwards; }
.fade-2-3s { animation: fade 2.3s ease; animation-fill-mode: forwards; }
.fade-2-6s { animation: fade 2.6s ease; animation-fill-mode: forwards; }
.fade-2-9s { animation: fade 2.9s ease; animation-fill-mode: forwards; }
.fade-3-2s { animation: fade 3.2s ease; animation-fill-mode: forwards; }
.fade-3-8s { animation: fade 3.8s ease; animation-fill-mode: forwards; }
.fade-4-4s { animation: fade 4.4s ease; animation-fill-mode: forwards; }
.fade-away { animation: fadeAway 7s ease; animation-fill-mode: forwards; }
.fade-away-bg { animation: fadeBg 2s ease; animation-fill-mode: forwards; }
.fade-away-2 { animation: fadeAway2 5s ease; animation-fill-mode: forwards; }
.move-away-2 { animation: moveAway 1s ease; animation-fill-mode: forwards; }
.move-away-2b { animation: moveAway2 1s ease; animation-fill-mode: forwards; }
.move-away-2c { animation: moveAway3 2s ease; animation-fill-mode: forwards; }
.move-3 { animation: move3 1s ease; }
.white-1 { color: #ffffff; }
.white-1-bg { background-color: #ffffff; }
.white-2 { color: #FFFDF6; }
.white-2-bg { background-color: #FFFDF6; }
.white-3 { color: #fdf1e3; }
.white-3-bg { background-color: #fdf1e3; }
.c-1 { color: #e9d9ca; }
.c-1-bg { background-color: #e9d9ca; }
.c-2 { color: #D2C2B1; }
.c-2-transparent { color: #d2c2b15b; }
.c-2-bg { background-color: #D2C2B1; }
.c-3 { color: #C2AC94; }
.c-3-bg { background-color: #C2AC94; }
.c-4 { color: #B19577; }
.c-4-bg { background-color: #B19577; }
.c-5 { color: #A98A69; }
.c-5-bg { background-color: #A98A69; }
.c-6 { color: #917353; }
.c-6-bg { background-color: #917353; }
.c-7 { color: #82684B; }
.c-7-bg { background-color: #82684B; }
.c-8 { color: #745C43; }
.c-8-bg { background-color: #745C43; }
.c-9 { color: #574532; }
.c-9-bg { background-color: #574532; }
.c-10 { color: #3e3224; }
.c-10-bg { background-color: #3e3224; }
.gray-1 { color: #494949; }
.gray-1-bg { background-color: #494949; }
.gray-2 { color: #373737; }
.gray-2-bg { background-color: #373737; }
.black-1 { color: #181818; }
.black-1-bg { background-color: #181818; }
.glass-30 { color: #ffffffb7; }
.glass-30-bg { background-color: #ffffffb7; }
.transparent { color: #ffffff00; }
body { background-color: #FFFDF6; -ms-overflow-style: none; }
body::-webkit-scrollbar { width: 0px; display: none; }
.height-100vh { height: 100vh; }
.width-100vh { width: 100vw; }
.cursor-click { cursor: pointer; }
.cursor-crosshair { cursor: crosshair; }
.cursor-default { cursor: default; }
.signature { font-family: 'Alex Brush'; }
.section { width: 2000px; }
@media (max-width: 2000px) { .section { width: 1800px; } }
@media (max-width: 1800px) { .section { width: 1600px; } }
@media (max-width: 1600px) { .section { width: 1400px; } }
@media (max-width: 1400px) { .section { width: 1200px; } }
@media (max-width: 1200px) { .section { width: 1000px; } }
@media (max-width: 1000px) { .section { width: 800px; } }
@media (max-width: 800px) { .section { width: 600px; } }
@media (max-width: 600px) { .section { width: 500px; } }
@media (max-width: 500px) { .section { width: 400px; } }
@media (max-width: 400px) { .section { width: 300px; } }
.section h2 { font-size: 140px; padding-bottom: calc(30px * 1.6); }
@media (max-width: 2000px) { .section h2 { padding-bottom: calc(26px * 1.6); font-size: 120px; } }
@media (max-width: 1800px) { .section h2 { padding-bottom: calc(21px * 1.6); font-size: 110px; } }
@media (max-width: 1600px) { .section h2 { padding-bottom: calc(16px * 1.6); font-size: 100px; } }
@media (max-width: 1400px) { .section h2 { padding-bottom: calc(12px * 1.6); font-size: 90px; } }
@media (max-width: 1200px) { .section h2 { padding-bottom: calc(10px * 1.6); font-size: 80px; } }
@media (max-width: 1000px) { .section h2 { padding-bottom: calc(8px * 1.6); font-size: 70px; } }
@media (max-width: 800px) { .section h2 { font-size: 75px; } }
@media (max-width: 600px) { .section h2 { font-size: 65px; } }
@media (max-width: 500px) { .section h2 { font-size: 60px; } }
@media (max-width: 400px) { .section h2 { font-size: 55px; } }
.section p { font-size: 36px; line-height: 1.2; }
@media (max-width: 2000px) { .section p { font-size: 32px; } }
@media (max-width: 1800px) { .section p { font-size: 29px; } }
@media (max-width: 1600px) { .section p { font-size: 26px; } }
@media (max-width: 1400px) { .section p { font-size: 23px; } }
@media (max-width: 1200px) { .section p { font-size: 20px; } }
@media (max-width: 1000px) { .section p { font-size: 17px; } }
@media (max-width: 800px) { .section p { font-size: 23px; } }
@media (max-width: 600px) { .section p { font-size: 20px; } }
@media (max-width: 500px) { .section p { font-size: 18px; } }
@media (max-width: 400px) { .section p { font-size: 16px; } }
.section h4 { font-size: 52px; margin: 0; }
@media (max-width: 2000px) { .section h4 { font-size: 48px; } }
@media (max-width: 1800px) { .section h4 { font-size: 43px; } }
@media (max-width: 1600px) { .section h4 { font-size: 39px; } }
@media (max-width: 1400px) { .section h4 { font-size: 34px; } }
@media (max-width: 1200px) { .section h4 { font-size: 30px; } }
@media (max-width: 1000px) { .section h4 { font-size: 25px; } }
@media (max-width: 800px) { .section h4 { font-size: 30px; } }
@media (max-width: 600px) { .section h4 { font-size: 26px; } }
@media (max-width: 500px) { .section h4 { font-size: 25px; } }
@media (max-width: 400px) { .section h4 { font-size: 23px; } }
.section-margin { height: 80px; }
@media (max-width: 2000px) { .section-margin { height: 77px; } }
@media (max-width: 1800px) { .section-margin { height: 70px; } }
@media (max-width: 1600px) { .section-margin { height: 65px; } }
@media (max-width: 1400px) { .section-margin { height: 60px; } }
@media (max-width: 1200px) { .section-margin { height: 55px; } }
@media (max-width: 1000px) { .section-margin { height: 50px; } }
@media (max-width: 800px) { .section-margin { height: 70px; } }
@media (max-width: 600px) { .section-margin { height: 65px; } }
@media (max-width: 500px) { .section-margin { height: 60px; } }
.section-margin-2 { height: calc(80px * 2); }
@media (max-width: 2000px) { .section-margin-2 { height: calc(77px * 2); } }
@media (max-width: 1800px) { .section-margin-2 { height: calc(70px * 2); } }
@media (max-width: 1600px) { .section-margin-2 { height: calc(65px * 2); } }
@media (max-width: 1400px) { .section-margin-2 { height: calc(60px * 2); } }
@media (max-width: 1200px) { .section-margin-2 { height: calc(55px * 2); } }
@media (max-width: 1000px) { .section-margin-2 { height: calc(50px * 2); } }
.section-margin-3 {
  height: calc(80px * 0.5); }
@media (max-width: 2000px) { .section-margin-3 { height: calc(77px * 0.5); } }
@media (max-width: 1800px) { .section-margin-3 { height: calc(70px * 0.5); } }
@media (max-width: 1600px) { .section-margin-3 { height: calc(65px * 0.5); } }
@media (max-width: 1400px) { .section-margin-3 { height: calc(60px * 0.5); } }
@media (max-width: 1200px) { .section-margin-3 { height: calc(55px * 0.5); } }
@media (max-width: 1000px) { .section-margin-3 { height: calc(50px * 0.5); } }
.section-margin-4 { height: 200px; transform: translateY(-3px); }
.section-margin-5 { height: 80px; }
@media (max-width: 2000px) { .section-margin-5 { height: 77px; } }
@media (max-width: 1800px) { .section-margin-5 { height: 70px; } }
@media (max-width: 1600px) { .section-margin-5 { height: 65px; } }
@media (max-width: 1400px) { .section-margin-5 { height: 60px; } }
@media (max-width: 1200px) { .section-margin-5 { height: 55px; } }
@media (max-width: 1000px) { .section-margin-5 { height: 50px; } }
@media (max-width: 800px) { .section-margin-5 { height: calc(70px * 0.75); } }
@media (max-width: 600px) { .section-margin-5 { height: calc(65px * 0.75); } }
@media (max-width: 500px) { .section-margin-5 { height: calc(60px * 0.75); } }
.section-margin-nav { height: calc(70px * 0.8); }
@media (max-width: 2000px) { .section-margin-nav { height: calc(66.6px * 0.8); } }
@media (max-width: 1800px) { .section-margin-nav { height: calc(63.3px * 0.8); } }
@media (max-width: 1600px) { .section-margin-nav { height: calc(60px * 0.8); } }
@media (max-width: 1400px) { .section-margin-nav { height: calc(56.6px * 0.8); } }
@media (max-width: 1200px) { .section-margin-nav { height: calc(53.3px * 0.8); } }
@media (max-width: 1000px) { .section-margin-nav { height: calc(50px * 0.8); } }
.section-margin-footer { height: calc(80px * 0.2); }
@media (max-width: 2000px) { .section-margin-footer { height: calc(77px * 0.2); } }
@media (max-width: 1800px) { .section-margin-footer { height: calc(70px * 0.2); } }
@media (max-width: 1600px) { .section-margin-footer { height: calc(65px * 0.2); } }
@media (max-width: 1400px) { .section-margin-footer { height: calc(60px * 0.2); } }
@media (max-width: 1200px) { .section-margin-footer { height: calc(55px * 0.2); } }
@media (max-width: 1000px) { .section-margin-footer { height: calc(50px * 0.2); } }
#home {
  background: url("../backgrounds/home.webp") no-repeat;
  background-size: cover !important;
  background-position: center;
  animation: fade 3s ease; }
@media (max-width: 800px) { #home { height: 100vh; animation: none; } }
#parallax-1-home { -ms-overflow-style: none; }
@media (max-width: 800px) { #parallax-1-home { display: none; } }
#parallax-1-home::-webkit-scrollbar { width: 0px; display: none; }
#title-siltstone {
  font-size: 12vw;
  font-family: 'Great Vibes', Times, serif;
  animation: fade2 3s ease; }
@media (max-width: 1400px) { #title-siltstone { font-size: 14vw; } }
@media (max-width: 1200px) { #title-siltstone { font-size: 16vw; } }
@media (max-width: 800px) { #title-siltstone { font-size: 120px; animation: none; } }
@media (max-width: 600px) { #title-siltstone { font-size: 108px; } }
@media (max-width: 500px) { #title-siltstone { font-size: 100px; } }
@media (max-width: 400px) { #title-siltstone { font-size: 85px; } }
#title-design-group {
  transform: translateY(-3.9vw);
  font-size: 4vw;
  margin: 0;
  font-family: 'Times New Roman', Times, serif;
  text-align: center;
  animation: fade2 3s ease; }
@media (max-width: 1400px) { #title-design-group { font-size: 4.5vw; transform: translateY(-4.5vw); } }
@media (max-width: 1200px) { #title-design-group { font-size: 5vw; transform: translateY(-5vw); } }
@media (max-width: 800px) { #title-design-group { font-size: 40px; transform: translateY(-45px); animation: none; } }
@media (max-width: 600px) { #title-design-group { font-size: 36px; transform: translateY(-42px); } }
@media (max-width: 500px) { #title-design-group { font-size: 33px; transform: translateY(-40px); } }
@media (max-width: 400px) { #title-design-group { font-size: 28px; transform: translateY(-34px); } }
#arrow-down {
  width: auto;
  height: 17px !important;
  margin-bottom: 20px;
  animation: fade 4.5s ease; }
@media (max-width: 1800px) { #arrow-down { height: 15px !important; } }
@media (max-width: 1600px) { #arrow-down { height: 14px !important; } }
@media (max-width: 1400px) { #arrow-down { height: 13px !important; } }
@media (max-width: 1200px) { #arrow-down { height: 12px !important; } }
@media (max-width: 1000px) { #arrow-down { height: 11px !important; } }
#parallax-2 {
  cursor: crosshair;
  font-family: 'Coolvetica Regular', Arial, Helvetica;
  color: #181818; }
#parallax-2 p { font-family: 'Coolvetica Regular', Arial, Helvetica; }
#about-us { height: 1000px; }
@media (max-width: 2000px) { #about-us { height: 900px; } }
@media (max-width: 1800px) { #about-us { height: 800px; } }
@media (max-width: 1600px) { #about-us { height: 750px; } }
@media (max-width: 1400px) { #about-us { height: 700px; } }
@media (max-width: 1200px) { #about-us { height: 650px; } }
@media (max-width: 1000px) { #about-us { height: 550px; } }
@media (max-width: 800px) { #about-us { height: auto; max-width: 500px; } }
@media (max-width: 600px) { #about-us { max-width: 475px; } }
@media (max-width: 500px) { #about-us { max-width: 375px; } }
#img-about-us-container { width: 50%; padding: 5% 10%; }
#img-about-us {
  background: url("../backgrounds/house-about.webp") no-repeat;
  background-size: cover;
  background-position: center; }
  #about-us-content { width: 50%; }
@media (max-width: 800px) { #about-us-content { width: 100%; } }
#about-us-content button {
  border: none;
  background: none;
  cursor: crosshair;
  transition: 0.5s ease;
  padding: 0; }
#about-us-content button:hover {
  transition: 0.7s ease;
  color: #C2AC94; }
#about-us-p-1 {
  padding-right: 15%;
  padding-top: calc(30px * 0.5); }
@media (max-width: 2000px) { #about-us-p-1 { padding-top: calc(26px * 0.5); } }
@media (max-width: 1800px) { #about-us-p-1 { padding-top: calc(21px * 0.5); } }
@media (max-width: 1600px) { #about-us-p-1 { padding-top: calc(16px * 0.5); } }
@media (max-width: 1400px) { #about-us-p-1 { padding-top: calc(12px * 0.2); } }
@media (max-width: 1200px) { #about-us-p-1 { padding-top: calc(10px * 0.5); } }
@media (max-width: 1000px) { #about-us-p-1 { padding-top: calc(8px * 0.5); } }
@media (max-width: 800px) { #about-us-p-1 { padding-top: calc(21px * 0.5); text-align: center; } }
@media (max-width: 600px) { #about-us-p-1 { padding-top: calc(16px * 0.5); } }
@media (max-width: 500px) { #about-us-p-1 { padding-top: calc(13px * 0.5); } }
#about-us-p-2 {
  padding-right: 15%;
  padding-bottom: calc(30px * 1.7); }
@media (max-width: 2000px) { #about-us-p-2 { padding-bottom: calc(26px * 1.7); } }
@media (max-width: 1800px) { #about-us-p-2 { padding-bottom: calc(21px * 1.7); } }
@media (max-width: 1600px) { #about-us-p-2 { padding-bottom: calc(16px * 1.7); } }
@media (max-width: 1400px) { #about-us-p-2 { padding-bottom: calc(12px * 1.7); } }
@media (max-width: 1200px) { #about-us-p-2 { padding-bottom: calc(10px * 1.7); } }
@media (max-width: 1000px) { #about-us-p-2 { padding-bottom: calc(8px * 1.7); } }
@media (max-width: 800px) { #about-us-p-2 { padding-bottom: calc(21px * 1.7); text-align: center; } }
@media (max-width: 600px) { #about-us-p-2 { padding-bottom: calc(16px * 1.7); } }
@media (max-width: 500px) { #about-us-p-2 { padding-bottom: calc(13px * 1.7); } }
#learn-more-plus { padding: 8px 0 0 8px; }
@media (max-width: 2000px) { #learn-more-plus { padding: 7px 0 0 7px; } }
@media (max-width: 1800px) { #learn-more-plus { padding: 6px 0 0 6px; } }
@media (max-width: 1600px) { #learn-more-plus { padding: 6px 0 0 5px; } }
@media (max-width: 1400px) { #learn-more-plus { padding: 5px 0 0 5px; } }
@media (max-width: 1200px) { #learn-more-plus { padding: 5px 0 0 4px; } }
@media (max-width: 1000px) { #learn-more-plus { padding: 4px 0 0 4px; } }
#meet-the-team-button { margin-bottom: 36px; }
@media (max-width: 2000px) { #meet-the-team-button { margin-bottom: 32px; } }
@media (max-width: 1800px) { #meet-the-team-button { margin-bottom: 26px; } }
@media (max-width: 1600px) { #meet-the-team-button { margin-bottom: 20px; } }
@media (max-width: 1400px) { #meet-the-team-button { margin-bottom: 15px; } }
@media (max-width: 1200px) { #meet-the-team-button { margin-bottom: 13px; } }
@media (max-width: 1000px) { #meet-the-team-button { margin-bottom: 10px; } }
#our-work {
  background: url("../backgrounds/timeline.avif") no-repeat;
  background-size: cover;
  background-position: center top;
  height: 3500px; }
@media (max-width: 2000px) { #our-work { height: 3150px; } }
@media (max-width: 1800px) { #our-work { height: 2800px; } }
@media (max-width: 1600px) { #our-work { height: 2450px; } }
@media (max-width: 1400px) { #our-work { height: 2100px; } }
@media (max-width: 1200px) { #our-work { height: 1750px; } }
@media (max-width: 1000px) { #our-work { height: 1400px; } }
#our-work-title {
  padding-left: 10%;
  padding-top: 80px; }
@media (max-width: 2000px) { #our-work-title { padding-top: 77px; } }
@media (max-width: 1800px) { #our-work-title { padding-top: 70px; } }
@media (max-width: 1600px) { #our-work-title { padding-top: 65px; } }
@media (max-width: 1400px) { #our-work-title { padding-top: 60px; } }
@media (max-width: 1200px) { #our-work-title { padding-top: 55px; } }
@media (max-width: 1000px) { #our-work-title { padding-top: 50px; } }
#our-work-cards { margin-top: 1920px; }
@media (max-width: 2000px) { #our-work-cards { margin-top: 1675px; } }
@media (max-width: 1800px) { #our-work-cards { margin-top: 1530px; } }
@media (max-width: 1600px) { #our-work-cards { margin-top: 1385px; } }
@media (max-width: 1400px) { #our-work-cards { margin-top: 1240px; } }
@media (max-width: 1200px) { #our-work-cards { margin-top: 1095px; } }
@media (max-width: 1000px) { #our-work-cards { margin-top: 950px; } }
.house-card {
  width: 660px;
  height: calc(660px * 0.5625);
  margin-bottom: 55px;
  box-shadow: 10px 20px 20px #0000003e; }
@media (max-width: 2000px) { .house-card {
  width: 590px;
  height: calc(590px * 0.5625);
  margin-bottom: 50px; } }
@media (max-width: 1800px) { .house-card {
  width: 520px;
  height: calc(520px * 0.5625);
  margin-bottom: 45px; } }
@media (max-width: 1600px) { .house-card {
  width: 450px;
  height: calc(450px * 0.5625);
  margin-bottom: 40px; } }
@media (max-width: 1400px) { .house-card {
  width: 380px;
  height: calc(380px * 0.5625);
  margin-bottom: 35px; } }
@media (max-width: 1200px) { .house-card {
  width: 310px;
  height: calc(310px * 0.5625);
  margin-bottom: 30px; } }
@media (max-width: 1000px) { .house-card {
  width: 240px;
  height: calc(240px * 0.5625);
  margin-bottom: 25px; } }
#house-1 {
background: url("../backgrounds/h1.webp") no-repeat;
background-size: cover;
background-position: center;
margin-left: 55%; }
#house-2 {
background: url("../backgrounds/h2.webp") no-repeat;
background-size: cover;
background-position: center;
margin-left: 45%; }
#house-3 {
background: url("../backgrounds/h3.webp") no-repeat;
background-size: cover;
background-position: center;
margin-left: 60%; }
#house-4 {
background: url("../backgrounds/h4.webp") no-repeat;
background-size: cover;
background-position: center;
margin-left: 50%; }
#house-5 {
background: url("../backgrounds/h5.webp") no-repeat;
background-size: cover;
background-position: center;
margin-left: 40%; }
#house-6 {
background: url("../backgrounds/h6.webp") no-repeat;
background-size: cover;
background-position: center;
margin-left: 55%; }
#house-7 {
background: url("../backgrounds/h7.webp") no-repeat;
background-size: cover;
background-position: center;
margin-left: 45%; }
.house-card-data { transition: 0.5s ease; }
.house-card-data p {
  font-family: 'Coolvetica Regular';
  color: #ffffff00;
  transition: 0.3s ease;
  margin-right: 5px;
  margin-top: 15px;
  font-size: 60px !important; }
@media (max-width: 2000px) { .house-card-data p { font-size: 52px !important; } }
@media (max-width: 1800px) { .house-card-data p { font-size: 48px !important; } }
@media (max-width: 1600px) { .house-card-data p { font-size: 43px !important; } }
@media (max-width: 1400px) { .house-card-data p { font-size: 39px !important; } }
@media (max-width: 1200px) { .house-card-data p { font-size: 34px !important; } }
@media (max-width: 1000px) { .house-card-data p { font-size: 30px !important; } }
.house-card-data i {
  color: #ffffff00;
  transition: 0.3s ease;
  font-size: 60px; }
@media (max-width: 2000px) {.house-card-data i {font-size: 52px; } }
@media (max-width: 1800px) {.house-card-data i {font-size: 48px; } }
@media (max-width: 1600px) {.house-card-data i {font-size: 43px; } }
@media (max-width: 1400px) {.house-card-data i {font-size: 39px; } }
@media (max-width: 1200px) {.house-card-data i {font-size: 34px; } }
@media (max-width: 1000px) {.house-card-data i {font-size: 30px; } }
.house-card-data button {
  background: none;
  border: none;
  transition: 0.3s ease;
  cursor: crosshair; }
.house-card-data-hover:hover {
  background: #e9d9ca;
  transition: 0.5s ease; }
.house-card-data-hover:hover p {
  transition: 0.3s ease;
  color: #82684B; }
.house-card-data-hover:hover i {
  transition: 0.3s ease;
  color: #82684B; }
.house-card-data-hover button:hover p {
  transition: 0.3s ease;
  color: #C2AC94; }
.house-card-data-hover button:hover i {
  transition: 0.3s ease;
  color: #C2AC94; }
#contact h2 {
  padding-left: 10%;
  padding-bottom: calc(30px * 1.6); }
@media (max-width: 2000px) { #contact h2 { padding-bottom: calc(26px * 1.6); } }
@media (max-width: 1800px) { #contact h2 { padding-bottom: calc(21px * 1.6); } }
@media (max-width: 1600px) { #contact h2 { padding-bottom: calc(16px * 1.6); } }
@media (max-width: 1400px) { #contact h2 { padding-bottom: calc(12px * 1.6); } }
@media (max-width: 1200px) { #contact h2 { padding-bottom: calc(10px * 1.6); } }
@media (max-width: 1000px) { #contact h2 { padding-bottom: calc(8px * 1.6); } }
#contact h3 {
  margin: 0;
  padding-left: 10%;
  text-wrap: nowrap;
  font-size: 30px; }
@media (max-width: 2000px) { #contact h3 { font-size: 26px; } }
@media (max-width: 1800px) { #contact h3 { font-size: 24px; } }
@media (max-width: 1600px) { #contact h3 { font-size: 21px; } }
@media (max-width: 1400px) { #contact h3 { font-size: 19px; } }
@media (max-width: 1200px) { #contact h3 { font-size: 17px; } }
@media (max-width: 1000px) { #contact h3 { font-size: 15px; } }
#contact-adress { padding-bottom: calc(30px * 0.1); }
@media (max-width: 2000px) {
#contact-adress { padding-bottom: calc(26px * 0.1); } }
@media (max-width: 1800px) { #contact-adress { padding-bottom: calc(21px * 0.1); } }
@media (max-width: 1600px) { #contact-adress { padding-bottom: calc(16px * 0.1); } }
@media (max-width: 1400px) { #contact-adress { padding-bottom: calc(12px * 0.1); } }
@media (max-width: 1200px) { #contact-adress { padding-bottom: calc(10px * 0.1); } }
@media (max-width: 1000px) { #contact-adress { padding-bottom: calc(8px * 0.1); } }
@media (max-width: 800px) { #contact-adress { padding-bottom: calc(3px); padding-top: calc(21px * 1.2); } }
@media (max-width: 600px) { #contact-adress { padding-top: calc(16px * 1.2); } }
@media (max-width: 500px) { #contact-adress { padding-top: calc(13px * 1.2); } }
#map-container {
  margin: 0 10%;
  padding: 2%; }
@media (max-width: 1200px) { #map-container { margin: 0 7%; } }
@media (max-width: 1000px) { #map-container { margin: 0 4%; } }
#gmap {
  width: 65%;
  height: 500px; }
@media (max-width: 2000px) { #gmap { height: 460px; } }
@media (max-width: 1800px) { #gmap { height: 430px; } }
@media (max-width: 1600px) { #gmap { height: 400px; } }
@media (max-width: 1400px) { #gmap { height: 360px; } }
@media (max-width: 1200px) { #gmap { height: 330px; } }
@media (max-width: 1000px) { #gmap { height: 300px; } }
.footer-section { width: 33.3%; }
.footer-section-2 { width: 30%; }
#footer-content {
  font-family: 'Coolvetica Black';
  padding-top: 70px; }
@media (max-width: 2000px) { #footer-content { padding-top: 67px; } }
@media (max-width: 1800px) { #footer-content { padding-top: 60px; } }
@media (max-width: 1600px) { #footer-content { padding-top: 55px; } }
@media (max-width: 1400px) { #footer-content { padding-top: 50px; } }
@media (max-width: 1200px) { #footer-content { padding-top: 45px; } }
@media (max-width: 1000px) { #footer-content { padding-top: 40px; } }
#footer-content button {
  font-family: 'Coolvetica Black';
  cursor: crosshair;
  background: none;
  border: none;
  font-size: 30px;
  text-align: start;
  width: 100%;
  transition: 0.2s ease; }
@media (max-width: 2000px) { #footer-content button { font-size: calc(32px * 0.7); } }
@media (max-width: 1800px) { #footer-content button { font-size: calc(29px * 0.7); } }
@media (max-width: 1600px) { #footer-content button { font-size: calc(26px * 0.7); } }
@media (max-width: 1400px) { #footer-content button { font-size: calc(23px * 0.7); } }
@media (max-width: 1200px) { #footer-content button { font-size: calc(20px * 0.8); } }
@media (max-width: 1000px) { #footer-content button { font-size: calc(17px * 0.9); } }
@media (max-width: 800px) { #footer-content button { font-size: calc(23px * 0.8); } }
@media (max-width: 600px) { #footer-content button { font-size: calc(20px * 0.8); margin-left: 5px; } }
#footer-content button:hover { color: #D2C2B1; transition: 0.2s ease; }
#footer-content h3 { font-size: 30px; }
@media (max-width: 2000px) { #footer-content h3 { font-size: calc(32px * 0.7); } }
@media (max-width: 1800px) { #footer-content h3 { font-size: calc(29px * 0.7); } }
@media (max-width: 1600px) { #footer-content h3 { font-size: calc(26px * 0.7); } }
@media (max-width: 1400px) { #footer-content h3 { font-size: calc(23px * 0.7); } }
@media (max-width: 1200px) { #footer-content h3 { font-size: calc(20px * 0.8); } }
@media (max-width: 1000px) { #footer-content h3 { font-size: calc(17px * 0.9); } }
#logo-img-footer { width: 45%; height: auto; }
@media (max-width: 800px) { #logo-img-footer { width: 85%; margin-top: 8px; } }
@media (max-width: 600px) { #logo-img-footer { width: 90%; margin-top: 7px; margin-right: 5px; } }
@media (max-width: 500px) { #logo-img-footer { margin-top: 6px; } }
@media (max-width: 400px) { #logo-img-footer { margin-top: 5px; } }
#all-r-reserved { padding-top: 70px; }
@media (max-width: 2000px) { #all-r-reserved { padding-top: 67px; } }
@media (max-width: 1800px) { #all-r-reserved { padding-top: 60px; } }
@media (max-width: 1600px) { #all-r-reserved { padding-top: 55px; } }
@media (max-width: 1400px) { #all-r-reserved { padding-top: 50px; } }
@media (max-width: 1200px) { #all-r-reserved { padding-top: 45px; } }
@media (max-width: 1000px) { #all-r-reserved { padding-top: 40px; } }
.footer-bottom { font-size: 24px; }
@media (max-width: 2000px) { .footer-bottom { font-size: 21px; } }
@media (max-width: 1800px) { .footer-bottom { font-size: 19px; } }
@media (max-width: 1600px) { .footer-bottom { font-size: 16px; } }
@media (max-width: 1400px) { .footer-bottom { font-size: 15px; } }
@media (max-width: 1200px) { .footer-bottom { font-size: 14px; } }
@media (max-width: 1000px) { .footer-bottom { font-size: 13px; } }
.footer-bottom-2 { font-size: 20px; margin-top: 5px; }
@media (max-width: 2000px) { .footer-bottom-2 { font-size: 17px; margin-top: 5px; } }
@media (max-width: 1800px) { .footer-bottom-2 { font-size: 15px; margin-top: 5px; } }
@media (max-width: 1600px) { .footer-bottom-2 { font-size: 13px; margin-top: 5px; } }
@media (max-width: 1400px) { .footer-bottom-2 { font-size: 12px; margin-top: 5px; } }
@media (max-width: 1200px) { .footer-bottom-2 { font-size: 11px; margin-top: 5px; } }
@media (max-width: 1000px) { .footer-bottom-2 { font-size: 10px; margin-top: 5px; } }
#mobile-version {
  display: none;
  font-family: 'Coolvetica Regular', Arial, Helvetica;
  color: #181818; }
@media (max-width: 800px) { #mobile-version { display: flex; } }
#mobile-about-us-buttons { margin-bottom: 10px; }
#mobile-selected-work { padding: 60px 0; }
@media (max-width: 400px) { #mobile-selected-work { padding: 55px 0; } }
@media (max-width: 800px) { #mobile-selected-work-title { font-size: 75px; } }
@media (max-width: 600px) { #mobile-selected-work-title { font-size: 65px; } }
@media (max-width: 500px) { #mobile-selected-work-title { font-size: 60px; } }
@media (max-width: 400px) { #mobile-selected-work-title { font-size: 55px; } }
@media (max-width: 800px) { #mobile-selected-work-title { padding-bottom: 20px; } }
@media (max-width: 600px) { #mobile-selected-work-title { padding-bottom: 18px; } }
@media (max-width: 500px) { #mobile-selected-work-title { padding-bottom: 16px; line-height: 1; } }
@media (max-width: 400px) { #mobile-selected-work-title { padding-bottom: 14px; } }
@media (max-width: 500px) { .project-row { flex-direction: column; } }
.mobile-house-img { border: none; }
@media (max-width: 800px) { .mobile-house-img {
  margin: 0 calc(60px * 0.1) calc(60px * 0.2) calc(60px * 0.1);
  width: calc(17.7px * 16);
  height: calc(17.7px * 9); } }
@media (max-width: 600px) { .mobile-house-img {
  margin: 0 calc(55px * 0.1) calc(55px * 0.2) calc(60px * 0.1);
  width: calc(14.7px * 16);
  height: calc(14.7px * 9); } }
@media (max-width: 500px) { .mobile-house-img {
  margin: 0 0 calc(50px * 0.25) 0;
  width: calc(19px * 16);
  height: calc(19px * 9); } }
@media (max-width: 400px) { .mobile-house-img {
  margin: 0 0 calc(45px * 0.25) 0;
  width: calc(18.2px * 16);
  height: calc(18.2px * 9); } }
#mobile-house-1 {
  background: url("../backgrounds/h1.webp") no-repeat;
  background-size: cover;
  background-position: center; }
#mobile-house-2 {
  background: url("../backgrounds/h2.webp") no-repeat;
  background-size: cover;
  background-position: center; }
#mobile-house-3 {
  background: url("../backgrounds/h3.webp") no-repeat;
  background-size: cover;
  background-position: center; }
#mobile-house-4 {
  background: url("../backgrounds/h4.webp") no-repeat;
  background-size: cover;
  background-position: center; }
#mobile-house-5 {
  background: url("../backgrounds/h7.webp") no-repeat;
  background-size: cover;
  background-position: center; }
#mobile-house-6 {
  background: url("../backgrounds/h6.webp") no-repeat;
  background-size: cover;
  background-position: center; }
#map-img {
  width: auto;
  object-fit: cover;
  max-width: 95%; }
@media (max-width: 800px) { #map-img { height: 340px; margin: 0 0 20px 0; } }
@media (max-width: 600px) { #map-img { height: 300px; margin: 0 0 18px 0; } }
@media (max-width: 500px) { #map-img { height: 200px; margin: 0 0 16px 0; } }
@media (max-width: 400px) { #map-img { height: 180px; margin: 0 0 14px 0; } }
#footer-mobile-div { width: 5%; }
@media (min-width: 501px) { #footer-mobile-div { display: none; } }
@media (max-width: 415px) { #terms-button { line-height: 1.05; margin-top: 5px; } }
@media (max-width: 335px) { #team-button { line-height: 1.05; margin-top: 5px; } }
#team-page {
  cursor: crosshair;
  font-family: 'Coolvetica Regular', Arial, Helvetica;
  color: #181818; }
.team-container { width: 2000px; }
@media (max-width: 2000px) { .team-container { width: 1800px; } }
@media (max-width: 1800px) { .team-container { width: 1600px; } }
@media (max-width: 1600px) { .team-container { width: 1400px; } }
@media (max-width: 1400px) { .team-container { width: 1200px; } }
@media (max-width: 1200px) { .team-container { width: 1000px; } }
@media (max-width: 1000px) { .team-container { width: 800px; } }
@media (max-width: 800px) { .team-container { width: 600px; } }
@media (max-width: 600px) { .team-container { width: 500px; } }
@media (max-width: 500px) { .team-container { width: 400px; } }
@media (max-width: 400px) { .team-container { width: 300px; } }
.team-container h1 {
  color: #745C43;
  font-size: 140px;
  padding-bottom: 30px; }
@media (max-width: 2000px) { .team-container h1 { padding-bottom: 26px; font-size: 120px; } }
@media (max-width: 1800px) { .team-container h1 { padding-bottom: 21px; font-size: 110px; } }
@media (max-width: 1600px) { .team-container h1 { padding-bottom: 16px; font-size: 100px; } }
@media (max-width: 1400px) { .team-container h1 { padding-bottom: 12px; font-size: 90px; } }
@media (max-width: 1200px) { .team-container h1 { padding-bottom: 10px; font-size: 80px; } }
@media (max-width: 1000px) { .team-container h1 { padding-bottom: 8px; font-size: 70px; } }
@media (max-width: 800px) { .team-container h1 { padding-bottom: 6px; font-size: 75px; } }
@media (max-width: 600px) { .team-container h1 { padding-bottom: 5px; font-size: 65px; } }
@media (max-width: 500px) { .team-container h1 { padding-bottom: 4px; font-size: 60px; } }
@media (max-width: 400px) { .team-container h1 { padding-bottom: 3px; font-size: 55px; } }
.team-container p { font-size: 36px; line-height: 1.2; }
@media (max-width: 2000px) { .team-container p { font-size: 32px; } }
@media (max-width: 1800px) { .team-container p { font-size: 29px; } }
@media (max-width: 1600px) { .team-container p { font-size: 26px; } }
@media (max-width: 1400px) { .team-container p { font-size: 23px; } }
@media (max-width: 1200px) { .team-container p { font-size: 20px; } }
@media (max-width: 1000px) { .team-container p { font-size: 17px; } }
@media (max-width: 800px) { .team-container p { font-size: 23px; } }
@media (max-width: 600px) { .team-container p { font-size: 20px; } }
@media (max-width: 500px) { .team-container p { font-size: 18px; } }
@media (max-width: 400px) { .team-container p { font-size: 16px; } }
.team-container h3 { font-size: 52px; margin: 0; }
@media (max-width: 2000px) { .team-container h3 { font-size: 48px; } }
@media (max-width: 1800px) { .team-container h3 { font-size: 43px; } }
@media (max-width: 1600px) { .team-container h3 { font-size: 39px; } }
@media (max-width: 1400px) { .team-container h3 { font-size: 34px; } }
@media (max-width: 1200px) { .team-container h3 { font-size: 30px; } }
@media (max-width: 1000px) { .team-container h3 { font-size: 25px; } }
@media (max-width: 800px) { .team-container h3 { font-size: 30px; } }
@media (max-width: 600px) { .team-container h3 { font-size: 26px; } }
@media (max-width: 500px) { .team-container h3 { font-size: 25px; } }
@media (max-width: 400px) { .team-container h3 { font-size: 23px; } }
.team-card {
  text-wrap: nowrap;
  width: 121%;
  width: 770.86px;
  height: 355.09px;
  padding: 7%;
  background-color: #e9d9ca;
  border-radius: 3px;
  margin-bottom: calc(30px * 3); }
@media (max-width: 2000px) { .team-card {
  margin-bottom: calc(26px * 3);
  width: 710.61px;
  height: 326.91px; } }
@media (max-width: 1800px) { .team-card {
  margin-bottom: calc(21px * 3);
  width: 641.48px;
  height: 297.47px; } }
@media (max-width: 1600px) { .team-card {
  margin-bottom: calc(16px * 3);
  width: 581.24px;
  height: 269.25px; } }
@media (max-width: 1400px) { .team-card {
  margin-bottom: calc(12px * 3);
  width: 512.13px;
  height: 239.81px; } }
@media (max-width: 1200px) { .team-card {
  margin-bottom: calc(10px * 3);
  width: 464.29px;
  height: 223.31px; } }
@media (max-width: 1000px) { .team-card {
  margin-bottom: calc(8px * 3);
  width: 407.61px;
  height: 205.56px; } }
@media (max-width: 800px) { .team-card { width: 442px; height: auto; } }
@media (max-width: 600px) { .team-card { width: 390px; } }
@media (max-width: 500px) { .team-card {
  flex-direction: column;
  align-items: center;
  width: 210px; } }
@media (max-width: 400px) { .team-card { width: 200px; } }
@media (min-width: 601px) { .team-card-1 { margin-right: 15%; } }
@media (min-width: 601px) { .team-card-2 { margin-left: 15%; } }
.team-card-no-hover { transform: translateX(calc(770.86px * 0.5)); }
@media (max-width: 2000px) { .team-card-no-hover { transform: translateX(calc(710.61px * 0.5)); } }
@media (max-width: 1800px) { .team-card-no-hover { transform: translateX(calc(641.48px * 0.5)); } }
@media (max-width: 1600px) { .team-card-no-hover { transform: translateX(calc(581.24px * 0.5)); } }
@media (max-width: 1400px) { .team-card-no-hover { transform: translateX(calc(512.13px * 0.5)); } }
@media (max-width: 1200px) { .team-card-no-hover { transform: translateX(calc(464.29px * 0.5)); } }
@media (max-width: 1000px) { .team-card-no-hover { transform: translateX(calc(407.61px * 0.5)); } }
@media (max-width: 800px) { .team-card-no-hover { transform: translateX(0); } }
.team-card-hover {
  transform: translateX(calc(770.86px * -0.5));
  opacity: 0%;
  transition: 0.7s ease; }
@media (max-width: 2000px) { .team-card-hover { transform: translateX(calc(710.61px * -0.5)); } }
@media (max-width: 1800px) { .team-card-hover { transform: translateX(calc(641.48px * -0.5)); } }
@media (max-width: 1600px) { .team-card-hover { transform: translateX(calc(581.24px * -0.5)); } }
@media (max-width: 1400px) { .team-card-hover { transform: translateX(calc(512.13px * -0.5)); } }
@media (max-width: 1200px) { .team-card-hover { transform: translateX(calc(464.29px * -0.5)); } }
@media (max-width: 1000px) { .team-card-hover { transform: translateX(calc(407.61px * -0.5)); } }
.team-card-hover p { text-wrap: wrap; }
@media (min-width: 801px) { .team-card-hover { box-shadow: 0 0 50px #0000002a; } }
.team-card-hover:hover {
  transition: 0.7s ease;
  opacity: 100%;
  display: none; }
.team-card-content { flex-grow: 1; }
@media (max-width: 500px) { .team-card-content { order: 2; } }
.team-img { width: 250px; height: 250px; }
@media (max-width: 2000px) { .team-img { width: 230px; height: 230px; } }
@media (max-width: 1800px) { .team-img { width: 210px; height: 210px; } }
@media (max-width: 1600px) { .team-img { width: 190px; height: 190px; } }
@media (max-width: 1400px) { .team-img { width: 170px; height: 170px; } }
@media (max-width: 1200px) { .team-img { width: 160px; height: 160px; } }
@media (max-width: 1000px) { .team-img { width: 150px; height: 150px; } }
@media (max-width: 800px) { .team-img { width: 140px; height: 140px; } }
@media (max-width: 600px) { .team-img { width: 120px; height: 120px; } }
@media (max-width: 500px) { .team-img { width: 110px; height: 110px; order: 1; } }
@media (max-width: 400px) { .team-img { width: 100px; height: 100px; } }
.team-img-1 { margin-right: 7%; }
@media (max-width: 500px) { .team-img-1 { margin: 10% 0; } }
.team-img-2 { margin-left: 7%; }
@media (max-width: 500px) { .team-img-2 { margin: 10% 0; } }
#footer-container-contact { display: none; transform: translateX(-10px); }
@media (min-width: 801px) { #footer-container-contact { display: flex; } }
#team-card-hovers { display: flex; }
@media (max-width: 800px) { #team-card-hovers { display: none !important; } }
#tac-page {
  cursor: crosshair;
  font-family: 'Coolvetica Regular', Arial, Helvetica;
  color: #181818; }
#tac-page h1 { text-align: center; line-height: 1; }
#tac-page p { font-family: 'Coolvetica Black', Arial, Helvetica; width: 75%; }
@media (max-width: 800px) { #tac-page p { width: 80%; } }
@media (max-width: 600px) { #tac-page p { width: 90%; } }
#tac-page .hidden {
  opacity: 0;
  transition: all 1s; }
#tac-page .show { opacity: 1; }
#tac-page .regular { font-family: 'Coolvetica Regular', Arial, Helvetica; }
#about-us-page {
  cursor: crosshair;
  font-family: 'Coolvetica Regular', Arial, Helvetica;
  color: #181818; }
#about-us-page h1 {
  text-align: center;
  color: #745C43;
  font-size: 140px;
  padding-bottom: 30px; }
@media (max-width: 2000px) { #about-us-page h1 { padding-bottom: 26px; font-size: 120px; } }
@media (max-width: 1800px) { #about-us-page h1 { padding-bottom: 21px; font-size: 110px; } }
@media (max-width: 1600px) { #about-us-page h1 { padding-bottom: 16px; font-size: 100px; } }
@media (max-width: 1400px) { #about-us-page h1 { padding-bottom: 12px; font-size: 90px; } }
@media (max-width: 1200px) { #about-us-page h1 { padding-bottom: 10px; font-size: 80px; } }
@media (max-width: 1000px) { #about-us-page h1 { padding-bottom: 8px; font-size: 70px; } }
@media (max-width: 800px) { #about-us-page h1 { padding-bottom: 6px; font-size: 75px; } }
@media (max-width: 600px) { #about-us-page h1 { padding-bottom: 5px; font-size: 65px; } }
@media (max-width: 500px) { #about-us-page h1 { padding-bottom: 4px; font-size: 60px; } }
@media (max-width: 400px) { #about-us-page h1 { padding-bottom: 3px; font-size: 55px; } }
#about-us-page p { font-size: 36px; line-height: 1.2; }
@media (max-width: 2000px) { #about-us-page p { font-size: 32px; } }
@media (max-width: 1800px) { #about-us-page p { font-size: 29px; } }
@media (max-width: 1600px) { #about-us-page p { font-size: 26px; } }
@media (max-width: 1400px) { #about-us-page p { font-size: 23px; } }
@media (max-width: 1200px) { #about-us-page p { font-size: 20px; } }
@media (max-width: 1000px) { #about-us-page p { font-size: 17px; } }
@media (max-width: 800px) { #about-us-page p { font-size: 23px; } }
@media (max-width: 600px) { #about-us-page p { font-size: 20px; } }
@media (max-width: 500px) { #about-us-page p { font-size: 18px; } }
@media (max-width: 400px) { #about-us-page p { font-size: 16px; } }
#whole-page-container {
  overflow: auto;
  height: calc(100vh - 70px);
  margin-top: 70px;
  -ms-overflow-style: none; }
@media (max-width: 2000px) { #whole-page-container { height: calc(100vh - 66.6px); margin-top: 66.6px; } }
@media (max-width: 1800px) { #whole-page-container { height: calc(100vh - 63.3px); margin-top: 63.3px; } }
@media (max-width: 1600px) { #whole-page-container { height: calc(100vh - 60px); margin-top: 60px; } }
@media (max-width: 1400px) { #whole-page-container { height: calc(100vh - 56.6px); margin-top: 56.6px; } }
@media (max-width: 1200px) { #whole-page-container { height: calc(100vh - 53.3px); margin-top: 53.3px; } }
@media (max-width: 1000px) { #whole-page-container { display: none; } }
#whole-page-container::-webkit-scrollbar { width: 0px; display: none; }
#whole-page-mobile { display: none; }
@media (max-width: 1000px) { #whole-page-mobile { display: flex; } }
#sticky {
  position: sticky;
  top: 0;
  height: 100%; }
#about-us-container {
  position: absolute;
  margin-left: 10%;
  width: 36%;
  text-align: center; }
.height-300 { height: 300vh; }
#about-us-image-container {
  position: absolute;
  margin-left: 54%;
  width: 36%; }
#about-us-page-image-bg {
  background: url("../img/ab-img-2.webp") no-repeat;
  background-size: cover;
  background-position: center;
  height: 70%; }
  #about-us-page-image { object-fit: cover; }
.section-about-us-mobile { text-align: center; }
@media (max-width: 1000px) { 
  .section-about-us-mobile { height: 500px; }
  .section-about-us-mobile p { width: 60%; }
  .section-about-us-mobile h1 { margin-bottom: 25px; } }
@media (max-width: 800px) {
  .section-about-us-mobile { height: 470px; }
  .section-about-us-mobile p { width: 70%; }
  .section-about-us-mobile h1 { margin-bottom: 22px; } }
@media (max-width: 600px) {
  .section-about-us-mobile { height: 425px; }
  .section-about-us-mobile p { width: 80%; }
  .section-about-us-mobile h1 { margin-bottom: 20px; } }
@media (max-width: 500px) { 
  .section-about-us-mobile { height: 400px; }
  .section-about-us-mobile p { width: 90%; }
  .section-about-us-mobile h1 { margin-bottom: 17px; } }
@media (max-width: 400px) {
  .section-about-us-mobile { height: 375px; }
  .section-about-us-mobile h1 { margin-bottom: 15px; } }
#about-us-bg-mobile {
  background: url("../img/ab-img-1b.webp") no-repeat;
  background-size: cover;
  background-position: center; }
#about-us-bg-mobile h1 { color: #e9d9ca; }
#about-us-bg-mobile p { color: #ffffff; font-family: 'Coolvetica Black'; }
#project-page {
  cursor: crosshair;
  font-family: 'Coolvetica Regular', Arial, Helvetica;
  color: #181818; }
#number-project {
  flex-grow: 1;
  margin: 0 0 0 10%;
  padding: 0; }
@media (max-width: 800px) { #number-project { margin: 0 0 0 5%; } }
.project-details-m { text-wrap: nowrap; margin-bottom: 22px; }
@media (max-width: 2000px) { .project-details-m { margin-bottom: 19px; } }
@media (max-width: 1800px) { .project-details-m { margin-bottom: 17.5px; } }
@media (max-width: 1600px) { .project-details-m { margin-bottom: 15px; } }
@media (max-width: 1400px) { .project-details-m { margin-bottom: 12.5px; } }
@media (max-width: 1200px) { .project-details-m { margin-bottom: 12px; } }
@media (max-width: 1000px) { .project-details-m { margin-bottom: 11px; } }
@media (max-width: 800px) { .project-details-m { margin-bottom: 10px; } }
@media (max-width: 600px) { .project-details-m { margin-bottom: 9px; } }
@media (max-width: 500px) { .project-details-m { margin-bottom: 8px; } }
.project-details-m-2 { margin-right: 5%; }
@media (max-width: 500px) { .project-details-m-2 { margin-right: 2%; } }
@media (max-width: 500px) { .project-details-m-2 { margin-right: 5%; } }
.project-details-m-3 { margin-right: 10%; }
@media (max-width: 800px) { .project-details-m-3 { margin-right: 5%; } }
@media (max-width: 500px) { .project-details-m-3 { display: none !important; } }
#photo-gallery { padding: 0 10%; }
@media (max-width: 800px) { #photo-gallery { padding: 0 5%; } }
#photo-gallery img { margin-bottom: 32px; }
@media (max-width: 2000px) { #photo-gallery img { margin-bottom: 28.8px; } }
@media (max-width: 1800px) { #photo-gallery img { margin-bottom: 25.6px; } }
@media (max-width: 1600px) { #photo-gallery img { margin-bottom: 22.36px; } }
@media (max-width: 1400px) { #photo-gallery img { margin-bottom: 19.2px; } }
@media (max-width: 1200px) { #photo-gallery img { margin-bottom: 16px; } }
@media (max-width: 1000px) { #photo-gallery img { margin-bottom: 12.8px; } }
@media (max-width: 800px) { #photo-gallery img { margin-bottom: 10.8px; } }
@media (max-width: 600px) { #photo-gallery img { margin-bottom: 9px; } }
#photo-gallery-division img { width: 49%; }
@media (max-width: 500px) { #photo-gallery-division { flex-direction: column; }
#photo-gallery-division img { width: 100%; } }
.gallery-img-left {margin-right: 1%; }
@media (max-width: 500px) {.gallery-img-left {margin-right: 0; } }
.gallery-img-right {margin-left: 1%; }
@media (max-width: 500px) {.gallery-img-right {margin-left: 0; } }
.gallery-img-small {height: 50%; }
#nav-desktop {
  position: fixed;
  transition: 0.3s ease;
  z-index: 100; }
.hidden-nav { display: none; background-color: rgba(255, 255, 255, 0.9); }
#nav-desktop-container { height: 70px; padding: 0 2%; }
  @media (max-width: 2000px) { #nav-desktop-container { height: 66.6px; } }
  @media (max-width: 1800px) { #nav-desktop-container { height: 63.3px; } }
  @media (max-width: 1600px) { #nav-desktop-container { height: 60px; } }
  @media (max-width: 1400px) { #nav-desktop-container { height: 56.6px; } }
  @media (max-width: 1200px) { #nav-desktop-container { height: 53.3px; } }
  @media (max-width: 1000px) { #nav-desktop-container { height: 50px; } }
  @media (max-width: 800px) { #nav-desktop-container { justify-content: center !important; } }
#nav-desktop-container button {
  padding: 0 calc(36px * 0.7);
  font-size: calc(36px * 0.7);
  background: none;
  border: none;
  transition: 0.3s ease; }
@media (max-width: 2000px) { #nav-desktop-container button { padding: 0 calc(32px * 0.7); font-size: calc(32px * 0.7); } }
@media (max-width: 1800px) { #nav-desktop-container button { padding: 0 calc(29px * 0.7); font-size: calc(29px * 0.7); } }
@media (max-width: 1600px) { #nav-desktop-container button { padding: 0 calc(26px * 0.7); font-size: calc(26px * 0.7); } }
@media (max-width: 1400px) { #nav-desktop-container button { padding: 0 calc(23px * 0.7); font-size: calc(23px * 0.7); } }
@media (max-width: 1200px) { #nav-desktop-container button { padding: 0 calc(20px * 0.7); font-size: calc(20px * 0.8); } }
@media (max-width: 1000px) { #nav-desktop-container button { padding: 0 calc(17px * 0.7); font-size: calc(17px * 0.9); } }
@media (max-width: 800px) { #nav-desktop-container button { padding: 0 calc(23px * 1); font-size: calc(23px * 1.1); } }
@media (max-width: 600px) { #nav-desktop-container button { padding: 0 calc(20px * 1); font-size: calc(20px * 1.1); } }
@media (max-width: 500px) { #nav-desktop-container button { padding: 0 calc(18px * 1); font-size: calc(18px * 1.1); } }
@media (max-width: 400px) { #nav-desktop-container button { padding: 0 calc(16px * 1); font-size: calc(16px * 1.1); } }
#nav-desktop-container button:hover { transition: 0.3s ease; background-color: #d2c2b15b; }
@media (max-width: 800px) { #terms-button-nav { display: none; } }